import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from './services/notification.service';
@Injectable()
export class InterceptorService {
    redirectURL: any='';


    constructor(private route: Router, private notify: NotificationService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.redirectURL = this.getParameterByName('continue') || '';
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if ([403].includes(event?.body?.status) && localStorage.getItem('user_token')) {
                        localStorage.clear();
                        // this.notify.notify(event?.body?.message, 'error', 3000);
                        this.route.navigate(['/logout'], { queryParams: { 'continue': this.redirectURL } })
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if ([403].includes(error.status) && localStorage.getItem('user_token')) {
                    localStorage.clear();                    
                    // this.notify.notify(error.error.message, 'error', 3000);
                    this.route.navigate(['/logout'], { queryParams: { 'continue': this.redirectURL } })
                }else if([401].includes(error.status)){
                    this.notify.notify(error.error.message, 'error', 3000)
                }
                return throwError(() => error)
            })
        );
    }

    //Get querystring value
    getParameterByName(name) {
        if (isPlatformBrowser(this.platformId)) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        }
    }
}
