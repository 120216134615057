import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { BrowserService } from './browser.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public httpOptionsUms;
  public httpOptions;
  public status;
  public device: any;
  public ip: any;
  public user_id: any;
  public mobile:boolean;
  // public user_name: string;
  public user_token: string = '';
  public encryptionKey: string = '';
  public place_id: any = '';
  public city_code = '';
  public app_id :string= '1';
  public language: number = 1;
  watcher: Subscription;
  public nodeUrl = null;
  public syncUrl = null;
  public umsUrl = null;
  public estUrl = null
  public gisUrl = null;
  public wms_url = null;
  public language_list = [];
  public allowResources = [];
  public apiemUrl = null;
  mobileview: boolean = false;
  redirectURL: string='';
  
  public user_info =  new BehaviorSubject<any>(this.browser.getLocalValue('user_info')); 
  public profile_image = new BehaviorSubject<any>(this.browser.getLocalValue('profile_image'));
 

  constructor(media: MediaObserver,
    private http: HttpClient, 
    private dialog:MatDialog,
    private route: Router,
    private browser:BrowserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.redirectURL =  this.getParameterByName('continue');
    
    
    this.setApiUrl();
    if (this.browser.getLocalValue('language_id')) {
      this.language = +this.browser.getLocalValue('language_id');
    }
    if (this.browser.getLocalValue('place_id')) this.place_id = this.browser.getLocalValue('place_id');
    if (this.browser.getLocalValue('app_id')){      
        this.app_id = this.browser.getLocalValue('app_id');
    } 
    if (this.browser.getLocalValue('city_code')) this.city_code = this.browser.getLocalValue('city_code');
    if (this.browser.getLocalValue('user_id')) this.user_id = this.browser.getLocalValue('user_id');
    if (this.browser.getLocalValue('user_token')) this.user_token = this.browser.getLocalValue('user_token');
    // this.user_name = this.browser.getLocalValue('user_name');
    if (this.user_id && this.place_id) {
      this.getUserDetail(this.user_id);
      this.getLanguageList();
    }
    media.asObservable().pipe(map((change: MediaChange[]) =>{
      if (change[0].mqAlias == 'sm') {
        this.mobile=true;
      }
      if (change[0].mqAlias == 'xs') {
        this.mobile=true;
      }
      else{
        this.mobileview=false;
      }
    }));

  }

  //Get querystring value
  getParameterByName(name) {
    if (isPlatformBrowser(this.platformId)) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
  }

  setApiUrl() {
    // let host = window.location.hostname;
    this.umsUrl = environment.umsUrl;
    this.apiemUrl = environment.apiemUrl;
    this.user_token = (this.user_token == '') ? environment.user_token : this.user_token;
    this.encryptionKey = environment.encryptionKey;
  }


  // setting header for UMS
  setHeader(content_type = 'application/json') {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', String(this.app_id));
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', String(this.place_id));
    headers = headers.append('user-token', this.user_token);
    this.httpOptions = { headers: headers };
  }
  // setting header for estater
  // setHeaderApiem(content_type = 'application/json') {
  //   let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
  //   let headers = new HttpHeaders();
  //   headers = headers.append('Content-Type', content_type);
  //   //headers = headers.append('USER-DEVICE', dInfo);
  //   //headers = headers.append('session-id', this.session_id);
  //   if (this.browser.getLocalValue('ip')) {
  //     headers = headers.append('USER-IP', this.ip);
  //   } else {
  //     headers = headers.append('USER-IP', '::');
  //   }
  //   headers = headers.append('USER-CITY', this.place_id);
  //   headers = headers.append('USER-TOKEN', this.user_token);
  //   headers = headers.append('USER-LANGUAGE', '' + this.language + '');
  //   headers = headers.append('USER-LANGUAGE-CODE', 'en');
  //   this.httpOptions = { headers: headers };
  // }


  setUploadHeaderApiem(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    headers = headers.append('USER-CITY', String(this.place_id));
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-LANGUAGE-CODE', 'en');
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }

  //Setting header for file upload
  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    headers = headers.append('city-id', String(this.place_id));
    headers = headers.append('USER-CITY', String(this.place_id));
    headers = headers.append('CITY-CODE', this.city_code?String(this.city_code):'');
    headers = headers.append('user-token', String(this.user_token));
    headers = headers.append('app-id', String(this.app_id));
    headers = headers.append('user-lang', 'en');
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }


  getData(url) {
    this.setHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }


  deleteData(url) {
    this.setHeader();
    return this.http.delete(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }


  // send a POST request to the API to create a data object
  postData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }


  postApiemData(url, body, response_type = null) {
    if ((body === null || body === undefined)) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderApiem('application/json', response_type);
    return this.http.post(this.apiemUrl + url, body, this.httpOptions);
  }

  
  // send a PATCH request to the API to update a data object
  patchData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  postFile(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  // getting list of property type
  getLanguageList() {
    this.getData('language')
      .subscribe(
        {next: (res: any) => {
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
            });
          }
        },
        error:(err)=>{
           
        }
      }
      );
  }

  // getting allow resources
  getAllowResources() {
    let body = `user/resource?place_id=${this.place_id}&user_id=${this.user_id}`
    this.getData(body)
      // .map(data => data.json())
      .subscribe(
        {next: (data: any) => {
          this.allowResources = data;        
        },
        error : (err) => {
          
        }}
      )
  }

  
  getUserDetail(id) {
    this.getData(`user?user_id=${id}`).subscribe((res: any) => {
      if (res.data) {
        this.setUser_info(res.data);
      }
    })
  }
  setUser_info(record) {
    let fname='', lname='';
    if (record.user_details['1'].first_name!=undefined) {
      fname = record.user_details['1'].first_name;
    }
    if (record.user_details['1'].last_name!=undefined) {
      lname= " " + record.user_details['1'].last_name;
    }
    let image = 'assets/images/default-user.svg';
    if (record.profile_image) {
      image = record.profile_image;
    }
    this.browser.setLocalValue('profile_image', image);
    this.profile_image.next(image);

    let user_info = {
      "fname": fname,
      "lname": lname,
      "gender": record.gender,
      "contact": record.phone_number,
      "country": record.nationality,
      "email": record.primary_email_id,
      "createdDate": record.created_date,
      "latitude": record.lat,
      "longitude": record.lon,
      "language": 'en',
      "imageURL": image,
      ...record
    }
    this.browser.setLocalValue('user_info', JSON.stringify(user_info));
    this.user_info.next(user_info);
  }


  ngOnDestroy() {
    if(this.watcher) this.watcher.unsubscribe();
  }
}
