import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';

@Component({
  selector: 'app-logout',
  template: `<div *ngIf="loader" style="margin: 20% auto; text-align: center; width: 600px;">Please wait for redirecting</div>`,
})
export class LogoutComponent implements OnInit {
  loader: boolean;

  constructor(private api:ApiService, private route: Router, private activatedRoute : ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any, private browser:BrowserService
    ) {
    
   }

   logout(all_device=null, redirectURL:string=null) {
    if(all_device=="true"){
      all_device = true;
    }else{
      all_device=false;
    }
    this.loader=true;
    this.api.getData("logout?all_device=" + all_device).subscribe(
      {next: (data) => {
        this.loader=false;
        // console.log("redirect url ", redirectURL);
        this.browser.clear();
        this.api.app_id = "1";
        this.api.user_id = null;

        if (redirectURL) {
          this.route.navigate(["/login"], {
            queryParams: { "continue": redirectURL },
          });
        } else {
          this.route.navigateByUrl("login");
        }
      },
      error: (err) => {
        this.loader=false;
        // console.log("redirect url ", redirectURL);
        this.browser.clear();
        this.api.app_id = "1";
        this.api.user_id = null;

        if (redirectURL) {
          this.route.navigate(["/login"], {
            queryParams: { "continue": redirectURL },
          });
        } else {
          this.route.navigateByUrl("login");
        }
      }}
    );
  }


  ngOnInit(): void {
    let redirect = this.activatedRoute.snapshot.queryParams['continue'];
    let all_device = this.activatedRoute.snapshot.queryParams['all_device'];
    redirect = redirect ? redirect : null;
    this.logout(all_device, redirect); 
  }

}
