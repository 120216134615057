import { Injectable } from '@angular/core';

@Injectable()
export class MenusService {
  activeset: string = '';
  constructor() { }

  setActive(active: string): void {
    let act = active;
    this.activeset = act;
  }

  getIsActive(key: string) {
    if (this.activeset == key) {
      return true;
    }
    return false;
  }

}
