import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule} from '@angular/material/toolbar';
import {  MatSnackBarModule } from '@angular/material/snack-bar';
import {  MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions,MatNativeDateModule } from '@angular/material/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule } from 'ng-recaptcha';
import { NotificationService } from './services/notification.service';
import { MenusService } from "./services/menus.service";
import { AuthGuard } from "./services/auth.guard";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ApiService } from "./services/api.service";
import { CommonService } from './services/common.service';
import { NavigateToService } from './services/navigate-to.service';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { BrowserService } from './services/browser.service';
import { I18nModule } from './i18n/i18n.module';
import { AutologoutService } from './services/autologout.service';
import { successComponent } from './public/success/success.component';
import { LogoutComponent } from './public/logout/logout.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InterceptorService } from './interceptor.service';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
}

@NgModule({
  declarations: [AppComponent,successComponent, LogoutComponent],
  imports:[BrowserModule.withServerTransition({ appId: 'ums-admin' }),
    RouterModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSnackBarModule,
    RecaptchaV3Module,
    MatDatepickerModule,
    MatNativeDateModule,
    RecaptchaModule,
    I18nModule,
    MatDialogModule,
    // StoreModule.forRoot(reducers),
    // EffectsModule.forRoot([ApplicationEffects]),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      backdropBorderRadius: '4px',
      primaryColour: '#f69230',
      secondaryColour: '#16ceed',
      tertiaryColour: '#23cb6b',
      fullScreenBackdrop: false
    })
  ],
  providers: [ApiService, NotificationService,
    MenusService, CommonService,
    AuthGuard,BrowserService,AutologoutService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky' },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
