export const environment = {
  production: false,
  umsUrl: "https://umsapinode.dev10.in/",
  marketsURL :"https://markets.dev10.in/",
  ecemURL :"https://e2.dev10.in/",
  apiemUrl: "https://apiem.dev10.in/",
  user_token:
    "TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=",
  encryptionKey: "QhdfV7@1764sdXA",
  childURL: [
    { id: "admin2", url: "https://admin2.dev10.in/sso.html" },
    { id: "de2", url: "https://de2.dev10.in/sso.html" },
    { id: "gisadmin", url: "https://g2.dev10.in/sso.html" },
    { id: "emsadmin", url: "https://emadmin.dev10.in/sso.html" },
  ],
  // childURL: [
  //   { id: "admin2", url: "http://localhost:4000/sso.html" },
  //   { id: "de2", url: "http://localhost:6600/sso.html" },
  //   { id: "gisadmin", url: "http://localhost:5001/sso.html" },
  //   { id: "emadmin", url: "http://localhost:2001/sso.html" },
  // ],
};
