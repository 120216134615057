import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
const APPLICATION_TIMEOUT_TIME = 1000 * 60 * 30; //in milliseconds
@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  public t;
 
  constructor(@Inject(PLATFORM_ID) private platformId: any, private router: Router,
    private api: ApiService,private notify:NotificationService) {
      if (this.api.user_id) {
        this.resetTimer();
      }
    if (isPlatformBrowser(platformId)) {
      document.body.addEventListener('click', () => this.resetTimer());// catches mouse clicks
      document.body.addEventListener('keypress', () => this.resetTimer());//catches keyboard actions
      document.body.addEventListener('mousemove', () => this.resetTimer());//catches keyboard actions
      document.body.addEventListener('mousedown', () => this.resetTimer());//catches keyboard actions
      document.body.addEventListener('wheel', () => this.resetTimer());//catches scrolling
    }
  }

  resetTimer() {
    if (isPlatformBrowser(this.platformId)) {
      clearTimeout(this.t);
      this.t = setTimeout(() => {
        this.router.navigate(['/logout'])
        this.notify.notify('Session Expired\nLogging out!',"warn",3500);
      },APPLICATION_TIMEOUT_TIME);  // time is in milliseconds (1000 is 1 second)
    }
  }
}
