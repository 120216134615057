import { Component, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
// import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiService } from './services/api.service';
import { AutologoutService } from './services/autologout.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { ShareService } from './services/share.service';
// import { NotificationService } from './services/notification.service';
// import { EncryptDecryptService } from './services/encryptdecrypt.service';
// import { CommonService } from './services/common.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  $isLoggedIn: Observable<boolean>;
  @HostBinding('class.application') class = 'application';
  constructor(private api: ApiService,private logout:AutologoutService,
    private router:Router, private activatedRoute: ActivatedRoute,
     @Inject(PLATFORM_ID) private platformId: any,
    //  private store: Store<fromRoot.State>
     ) {
  }

  ngOnInit(){
    // if (isPlatformBrowser(this.platformId)) {
    // this.$isLoggedIn = this.store.select(fromRoot.selectIsLoggedIn);
    // this.$isLoggedIn.subscribe((state:any)=>{
    //   if(!state){
    //     this.router.navigate(['/logout'])
    //   }
    // })
    //   document.body.addEventListener('click', (e) => {
    //     this.extendLogout();
    //   }
    //   )
    // }
  }
  // logIn(){
  //   if (isPlatformBrowser(this.platformId)) {
  //   this.store.dispatch(new ApplicationActions.LogIn());
  //   }
  // }
  // extendLogout(){
  //   if (isPlatformBrowser(this.platformId)) {
  //   this.store.dispatch(new ApplicationActions.ExtendLogoutTimer());
  //   }
  // }
  
}
