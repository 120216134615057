import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { LogoutComponent } from './public/logout/logout.component';
import { successComponent } from './public/success/success.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    //loadChildren: () => import('./public/login/login.module').then(m => m.LoginModule),
    loadChildren:  () => import('./public/login/login.module').then(m => m.LoginModule),
    // canActivate: [LoginGuard]
  },
  {
    path: 'signup',
    //loadChildren: () => import('./public/signup/signup.module').then(m => m.SignupModule),
    loadChildren: () => import('./public/signup/signup.module').then(m => m.SignupModule),
    // canActivate: [LoginGuard]
  },
  {
    path: 'forgetpassword',
    //loadChildren: () => import('./public/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    loadChildren: () => import('./public/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    // canActivate: [LoginGuard]
  },
  {
    path: 'verify',
    //loadChildren: () => import('./public/verify/verify.module').then(m => m.VerifyModule),
    loadChildren:() => import('./public/verify/verify.module').then(m => m.VerifyModule),
    // canActivate: [LoginGuard]
  },
  {
    path: 'app',
    // loadChildren: () => import('./public/city-select/city.module').then(m => m.CityModule),
    loadChildren: () => import('./public/city-select/city.module').then(m => m.CityModule),
    // canActivate: [CityGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule),
    // canActivate: [CityGuard]
  },
  {
    path: 'success',
   component: successComponent
  },
  {
    path: 'logout',
   component: LogoutComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{ initialNavigation: 'enabledBlocking' }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
