import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BrowserService } from 'src/app/services/browser.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'success',
  template: `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;"><p>Please walt for redirect</p></div>`,
})
export class successComponent implements OnInit {

  
  constructor(private http: HttpClient,
    private browser: BrowserService,
    private api:ApiService,
    private route: Router,
    private activateRouter: ActivatedRoute) {
      
   
   }

  ngOnInit() {
   
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.doLogin();
    }, 1000);
  }


  doLogin() {
    let app_id='1';
    let place_id='13';
    if (this.browser.getLocalValue('place_id')) place_id = this.browser.getLocalValue('place_id');
    if (this.browser.getLocalValue('app_id')){      
      app_id = this.browser.getLocalValue('app_id');
      app_id.toString();      
    }
    
    let headers = new HttpHeaders();
    headers = headers.append('app-id', app_id);
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', place_id.toString());

    let token='';
    if(this.activateRouter.snapshot.queryParams.token){
      token = this.activateRouter.snapshot.queryParams.token;
    }
    
    this.http
      .post(`${environment.umsUrl}google-login`, {
        auth_token: token,
      },
      {headers: headers}
      )
      .subscribe(
        (res:any) => {         
          if(res && res?.data){
          let record = res.data;
          let fname='';
          let lname='';
            this.browser.setLocalValue('is_super_admin', record.is_super_admin);
            this.browser.setLocalValue('user_token', record.token);
            this.browser.setLocalValue('user_id', record.user_id);
            this.browser.setLocalValue('created_date', record.created_date);
            
            if (record.user_details[this.api.language].first_name!=undefined) {
              fname = record.user_details[this.api.language].first_name;
            }
            else{
              fname="-";
            }
            if (record.user_details[this.api.language].last_name!=undefined) {
              lname= " " + record.user_details[this.api.language].last_name;
            }
            else{
              fname=" " + "-";
            }
            this.api.user_id = record.user_id;
            // this.api.user_name = name;
            this.api.user_token = record.token;
            let image = '../../../assets/images/default-user.svg';
            if (record.profile_image) {
              image = record.profile_image;
            }
            this.browser.setLocalValue('profile_image', image);
            let user_info = {
              "fname": fname,
              "lname": lname,
              "gender": record.gender,
              "contact": record.phone_number,
              "country": record.nationality,
              "email": record.primary_email_id,
              "createdDate": record.created_date,
              "latitude": record?.lat,
              "longitude": record?.lon,
              "language": 'en',
              "imageURL": image,
              ...record
            }
            this.browser.setLocalValue('user_info', JSON.stringify(user_info));
            this.route.navigateByUrl('/app');
          }else{
            this.route.navigateByUrl('/login');
          }
        },
        (error) => {
          console.log(error);
          this.route.navigateByUrl('/login');
        }
      );
  }
  
}
