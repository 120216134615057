import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { relativeTimeRounding } from 'moment';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { BrowserService } from './browser.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(@Inject(PLATFORM_ID) private platformId: any,private router: Router, private api: ApiService,private browser:BrowserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (isPlatformBrowser(this.platformId)) {
    if (this.api.user_id && this.browser.getLocalValue('user_token')) {
      return true;
    } else {
      this.router.navigate(['/logout'])
      // this.router.navigateByUrl('/admin/login');
      return false;
    }
  }
  if (isPlatformServer(this.platformId)) {
    return true;
  }
  }
}