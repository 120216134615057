import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Injectable, Inject } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
// import { TranslateCacheService } from 'ngx-translate-cache';

@Injectable()
export class BrowserService {
    isMobile: boolean = false;
    selectedLang: string = '';
    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        //device check
        // this.selectedLang = isPlatformBrowser(this.platformId)
        //     ? translateCacheService.getCachedLanguage() || translate.getBrowserLang() || 'en'
        //     : 'en';

        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = (window.innerWidth < 768);
        }
    }
    // get and set and remove value on localstorage
    getLocalValue(key: string): any {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(key)
        }
    }
    setLocalValue(key: string, value: any) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    removeLocalValue(key:any){
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
    }

    //set and get cookie 
    setCookie(cname: any, cvalue: any, exdays: any) {
        if (isPlatformBrowser(this.platformId)) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
    }
    getCookie(cname: any) {
        if (isPlatformBrowser(this.platformId)) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
        return "";
    }

    //get Elements By Tag Name
    getElements(name:string ,key:string){
        let htmlele;
        if (isPlatformBrowser(this.platformId)) {
            switch (name) {
                case 'ByTagName' :
                    htmlele = document.getElementsByTagName(key);
                    break;
                case 'ById' :
                    htmlele =  document.getElementById(key);
                    break;
                case 'ByClassName' :
                    htmlele =  document.getElementsByClassName(key);
                    break;
            }
        }
        return htmlele;
    }

      //get window
      getWindowObject(name:string){
        let htmlele;
        if (isPlatformBrowser(this.platformId)) {
            switch (name) {
                case 'href' :
                    htmlele = window.location.href;
                    break;
                case 'reload' :
                    htmlele =  window.location.reload();
                    break;
            }
        }
        return htmlele;
    }

// clear localstorage
    clear(){
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
        }
    }
   
}