import { NgModule, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of } from 'rxjs';
import { join } from 'path';
import { readFileSync } from 'fs';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    
    if (isPlatformBrowser(this.platform)) {
      translateCacheService.init();
    }
    translate.addLangs(['en', 'ar']);
    const browserLang = isPlatformBrowser(this.platform)
      ? translateCacheService.getCachedLanguage() || translate.getBrowserLang() || 'en'
      : this.getLangFromServerSideCookie() || 'en';
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }
  public getLangFromServerSideCookie():any {
    if (this.req) {
      return 'en'
      //return this.req.cookies.lang;
    }
  }
}
export function translateLoaderFactory(httpClient: HttpClient, platform: any) {
  if(typeof localStorage !== "undefined"){
    return new TranslateHttpLoader(httpClient);
  }else{
    return new TranslateFSLoader();
  }
  //return new TranslateHttpLoader(httpClient)
}

export function translateCacheFactory(
  translateService: TranslateService,
  translateCacheSettings: TranslateCacheSettings
) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
export class TranslateFSLoader implements TranslateLoader {
  constructor(private prefix = 'i18n', private suffix = '.json') { }
  public getTranslation(lang: string): Observable<any> {
    const path = join(__dirname, '../browser/assets/', this.prefix, `${lang}${this.suffix}`);
    const data = JSON.parse(readFileSync(path, 'utf8'));
    return of(data);
  }
  
}